import React from "react";
function Header() {
    return (
        <div id='main'>
            
            <div className='name'>
                <h1><span>Building India’s first technology platform</span> to cater to the needs of Kirana ecosystem.</h1>
                <p className='details'>Pioneering the transformation of the Kirana stores into smart Kiranas to cater to the evolving needs of Indian consumers</p>
                <a href='https://play.google.com/store/apps/details?id=com.beazy' className='cv-btn'>Download</a>
                {/* <a href='https://play.google.com/store/apps/details?id=com.beazy' className='cv-btn'>Partner With Us</a> */}
            </div>
        </div>
    )
}

export default Header;