import Testimg1 from '../utils/test1.webp'
import Testimg2 from '../utils/test2.webp'
import Testimg3 from '../utils/test3.webp'
export const items = [
    {
        status: "inactive",
        imgSrc: Testimg1,
        name: "Shubham Gupta",    
        position: "Akshat Daily Needs",
        quote: "I have been using Beazy app for over 4 weeks now. The app is very easy to use.  I feel really empowered now, as i have the complete visibility into my stock, my daily sales, profit, items which are low on stock. I don’t have to use other apps or notebook to track the sale i have done on credit, this feature is part of the app.  My wife also uses the app, and I get the complete picture of what is happening in my shop, when i am even away. Overall i really like the app"
    },
    {
        status: "inactive",
        imgSrc: Testimg2,
        name: "Nitesh Negi",
        position: "Naira Mart", 
        quote: "Before starting my mart I used to work in the hospitality sector in Doha. I had some ideas to attract and retain customers but was not sure how to implement them. With Beazy help I’m now thinking of providing customer loyalty points to my customers. Beazy has made the management of my shop very easy. Customers are really happy as they are now getting experiences which they normally would get in the mall or big stores."
    },
    {
        status: "inactive",
        imgSrc: Testimg3,
        name: "Devansh Gunwant",
        position: "Gunwant Store",
        quote: "Personally, I find beazy app to be very useful in terms of keeping the track of the sales, daily profit, and stock in the store. It's very easy to use and easy to update stock. Also, it has rewards just for completing sales transactions. It made it very easy to track customer credits and other things.  For shopkeepers must definitely try this app out."
    },
];

export default items;