import RT1 from '../utils/rt1.svg'
import RT2 from '../utils/rt2.svg'
import RT3 from '../utils/rt3.svg'
import RT4 from '../utils/rt4.svg'
import RT5 from '../utils/rt5.svg'
import DB1 from '../utils/db1.svg'
import DB2 from '../utils/db2.svg'
import DB3 from '../utils/db3.svg'
import DB4 from '../utils/db4.svg'
import DB5 from '../utils/db5.svg'
import React from 'react';
import FeaturesBox from './FeaturesBox';
// import images from '../utils/feature_3.png'
import '../css/Feature.css'
let retailerData=[
    {
        img:RT1,
        Title:"Credit",
        disc:"Easy access to credit to grow your business."

    },
    {
        img:RT2,
        Title:"Complete shop information on mobile",
        disc:"Get all the information of the shop on your Mobile phone - Credit, Daily/Weekly/Monthly sales / Stock information, and low stock alerts"

    },
    {
        img:RT3,
        Title:"Take your store online",
        disc:"Increase your reach to customers who likes to order online"

    },
    {
        img:RT4,
        Title:"Product recommendations",
        disc:"Our AI/ML-based product recommendation engine provides recommendations to shopkeepers on what products can sell well in their area."

    },
    {
        img:RT5,
        Title:"Online ordering",
        disc:"Hassle-free ordering using the app, and get access to high margin products."

    },
]
let distributerData=[
    {
        img:DB1,
        Title:"Credit for growth",
        disc:"Access to credit to grow your business."

    },
    {
        img:DB2,
        Title:"Access to new customers",
        disc:"Get access to the network of Beazy powered retail stores."

    },
    {
        img:DB3,
        Title:"Direct connect to retailer.",
        disc:"Brand’s can connect directly with the retailers."

    },
    {
        img:DB4,
        Title:"Automated orders",
        disc:"Get orders on app from the retailers, no need to send personnel to collect orders."

    },
    {
        img:DB5,
        Title:"Customer analytics",
        disc:"Get information on which products are selling in which area."

    },
]
const Features = () => {
    return (
        <>
            <div className="feature">
                <h1>
                Organize Kirana store data and make it accessible and useful
                </h1>
                <div className='feature_body'>
                    <div className='retailer_section'>
                        <h3 className='feature_heading'>Retailer</h3>
                        <ol className='retailer_feature'>
                            {

                               retailerData.map(({img,Title,disc})=><li><FeaturesBox img={img} title={Title} discription={disc}/></li>) 
                            }
                        </ol>
                    </div>
                    <div className='distributer_section'>
                        <h3 className='feature_heading'>Distributor</h3>
                        <ol className='distributer_feature'>
                        {
                            distributerData.map(({img,Title,disc})=><li><FeaturesBox img={img} title={Title} discription={disc}/></li>) 
                        }
                        </ol>
                    </div>
                </div>
            </div>  
        </>
    );
}

export default Features;
