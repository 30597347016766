import '../css/Model1.css'
import Member1 from '../utils/Member1.webp';
import Chetan from '../utils/Chetan.webp';
// import Member3 from '../utils/Member3.jfif';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
export default function Model1() {
  let data=[
    {
      name:"Ritesh Joshi",
      imgUrl:Member1,
      position:"Co Founder",
      description:"Consulting and Data Science, hiking and travel enthusiast. Ex EY, IBM.",
      linkedInUrl:"https://www.linkedin.com/in/meriteshjoshi"
    },
    {
      name:"Chetan Bisht",
      imgUrl:Chetan,
      position:"Co Founder",
      description:"Learner, Product enthusiast, Photographer and mountain lover. Ex Atlassian and Sabre.",
      linkedInUrl:"https://www.linkedin.com/in/bishtchetan"
    }
  ]
  return (
    <>
    <div id='model1'>
        <h1 className='model-title'>Our Team Members</h1>
        <div className="model-divider"></div>
        <div className="members">
          {
            data.map(({name,imgUrl,position,description,linkedInUrl})=><div className="model1_member">
            <img src={imgUrl} alt="none"/>
            <div className="description">
                <h1>{name}</h1>
                <h2>{position}</h2>
                <p>{description}</p>
                <a href={linkedInUrl} className="social-media">        
                  <LinkedInIcon/>
                </a>
            </div>
          </div>
          )}
        </div>
    </div>
    </>
  );
}