import React, { useEffect } from "react";
import "./App.css";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ReactGA from "react-ga";
import { Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Home from "./Components/Home";

const HOST_TRACKING_ID = "UA-254780089-1";
ReactGA.initialize(HOST_TRACKING_ID);
function App() {
  useEffect(() => {
    let event = window.addEventListener("load", () => {
      ReactGA.event({
        category: "loaded",
        action: "load",
      });
      return () => window.removeEventListener("load", event);
    });
  }, []);
  return (
    <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/privacypolicy" element={<PrivacyPolicy />}/>
      </Routes>
         <Footer/>
    </div>
  );
}

export default App;
