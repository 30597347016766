import { Link } from 'react-router-dom';
import '../css/Footer.css';
import lo from '../utils/Beazy-Logo-image.svg';
import fb from '../utils/circle_facebook_icon.webp';
import Linked from '../utils/circle_linkedin_icon.webp';
import youtube from '../utils/circle_youtube_icon.webp';

function Footer() {
      return (
            <footer>
                  <div className="Main-Container">
                        <div className="Upper-Container">
                              <div className="Content">
                                    <div className="Image">
                                          <img src={lo} alt="Hey there." />

                                    </div>
                                    <div className="Content-Span">
                                          <a href="https://play.google.com/store/apps/details?id=com.beazy"><img src="https://www.freepnglogos.com/uploads/google-play-png-logo/get-it-on-google-play-google-play-badge-png-logos-23.png"  alt="get it on google play, google play badge png logos" /></a>
                                    </div>
                                    <div className="Link"></div>
                              </div>
                              <div className="Content">
                                    <div className="SecondOne">
                                          <div><h2>Quick Links</h2></div>
                                          <div className="SecondOne-Contain"><p>About us</p></div>
                                          <div className="SecondOne-Contain"><p>Contact us</p></div>
                                          <div className="SecondOne-Contain"><p>Become Partner</p></div>
                                          <div className="SecondOne-Contain"><Link to="/privacypolicy"><p>Privacy Policy</p></Link></div>
                                    </div>
                              </div>
                              
                              <div className="Content">
                                    <div className="FifthOne">
                                          <div className="HeadContainone"><h2>Contact</h2></div>
                                          <div className="EmailContain"><a href="mailto:support@Beazypvtltd.com">info@beazy.in</a></div>
                                          <div className="HeadContaintwo"><h2>Address</h2></div>
                                          <div className="AddressContain"><p> FLAT NO.1003, TOWER 7, 10TH FLOOR, SIGNATURE GLOBAL, GRAND EVA, SECTOR 103 GURUGRAM Gurgaon HR 122002 IN .</p> </div>
                                    </div>
                              </div>
                        </div>
                        <div className="Lower-Container">
                              <div className="Lower-Contain">
                                    <div className="Copyright">
                                          <span>Copyright © 2023 Beazy Tech Pvt. Ltd. | All rights reserved</span>
                                    </div>
                                    <div className="Links">
                                          <a href="https://www.facebook.com/beazytech?mibextid=LQQJ4d" target="_blank" rel='noreferrer'>
                                                <div className="Image-Contain">
                                                      <img src={fb} alt="none" />
                                                </div>
                                          </a>
                                          <a href=" https://youtube.com/@beazytech" target="_blank" rel='noreferrer'>
                                                <div className="Image-Contain">
                                                      <img src={youtube} alt="Hey" />
                                                </div>
                                          </a>
                                          <a href="https://www.linkedin.com/company/beazytech/" target="_blank" rel="noreferrer">
                                                <div className="Image-Contain">
                                                      <img src={Linked} alt="Hey" />
                                                </div>
                                          </a>
                                    </div>
                              </div>
                        </div>
                  </div>
            </footer>
      )
}

export default Footer;