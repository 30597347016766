import '../css/Model4.css'
import Ashish from '../utils/Ashish.webp';
import Roop from '../utils/Roop.webp';
import Apurv from '../utils/Apurv.webp';
import Anukriti from '../utils/Anukriti.webp';
import Pulkit from '../utils/Pulkit.webp';
import Harit from '../utils/Harit.webp';
import Akshit from '../utils/Akshit.webp'

export default function Model4() {
  const data=[
    {
      imgUrl:Ashish,
      name:"Ashish Pandey",
      position:"App Developer",
      linkedInUrl:"https://www.linkedin.com/in/zeph7/",
    },
    {
      imgUrl:Pulkit,
      name:"Pulkit Chausali",
      position:"App Developer",
      linkedInUrl:"https://www.linkedin.com/in/pulkit-chausali-8a66b4220",
    },
    {
      imgUrl:Apurv,
      name:"Apurv Tewari",
      position:"Web Developer",
      linkedInUrl:"https://www.linkedin.com/in/apurv-tewari-435b27197/",
    },
    {
      imgUrl:Harit,
      name:"Harit Sharma",
      position:"Web Developer",
      linkedInUrl:"https://www.linkedin.com/in/harit-sharma-567b18197/",
    },
    {
      imgUrl:Roop,
      name:"Roop Kumar",
      position:"App Developer",
      linkedInUrl:"https://www.linkedin.com/in/roop-kumar-71a084193/",
    },
    {
      imgUrl:Akshit,
      name:"Akshit Sanwal",
      position:"Web Developer",
      linkedInUrl:"https://www.linkedin.com/in/akshit-sanwal-74863a1aa/",
      },
    {
      imgUrl:Anukriti,
      name:"Anukriti Mishra",
      position:"Web Developer",
      linkedInUrl:"https://www.linkedin.com/in/anukriti-mishra-172440215/",
    },
  ]
  return (
    <div id='model4'>
        <ol className="model4_members">
          {
          data.map(({imgUrl,name,position,linkedInUrl})=><a href={linkedInUrl} className="model4_member">
            <img src={imgUrl} alt="none"/>
            <div className="description">
                <h1>{name}</h1>
                <h2>{position}</h2>
                <div className="social-media">
                </div>
            </div>
          </a>
          )}
        </ol>
    </div>
  );
}