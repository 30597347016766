import React from 'react';
import "../css/FeatureBox.css"
const FeaturesBox = ({img,title,discription}) => {
    return (
        <>
            <div className='featureBox_main'>
                <div className='feature_image'>
                    <img src={img} alt="none" />
                </div>
                <div className='feature_description'>
                    <h1>{title}</h1>
                    <p>{discription}</p>
                </div>
            </div>
        </>
    );
}

export default FeaturesBox;
