import React from 'react';
import Header from "./Header";
import About from "./About";
import Features from "./Features";
import aboutimage from "../utils/aboutgif.webp";
import "../App.css";
import Model1 from "./Model1";
import Testimonial from "./Testimonial";
import Model4 from "./Model4";

const Home = () => {
    return (
        <>
            <div>
            <section id="header">
          <Header />
        </section>
        <section id="features">
          <Features />
        </section>
        <section id="about">
          <About
            image={aboutimage}
            title="Our vision is to create the largest network of Kiranas which are equipped to serve the evolving needs of Indian consumers."
            button="Get The APP"
            />
        </section>
        <section id="team">
          <Model1 />
        </section>
          <Model4 />
         <Testimonial />
            </div>  
        </>
    );
}

export default Home;
