import React from 'react';
import "../css/privacyPolicy.css"
const PrivacyPolicy = () => {
    return (
        <>
            <div className='privacy_policy'>
                <h1>Privacy Policy | Beazy</h1>
                <p>Date updated:  5 May 2023</p>
                <p>This privacy notice for Beazy tech private limited ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:

                Download and use our mobile application (Beazy), or any other application of ours that links to this privacy notice
                Engage with us in other related ways, including any sales, marketing, or events.

                The app provides an easy way for the users to 
                <ul className='app_usage'>
                    <li> Record, search, and sell their Inventory</li> 
                     <li> Capture every transaction with details like customer name, customer phone number, etc.</li>
                      <li> Record credit and debit information </li> 
                      <li>Easily send receipt to the customers as message or on WhatsApp </li>
                    <li> Online store front - publish your catalog and sell online via a web based store front.</li>
                    <li>Loyalty programme - retain your customers by giving them loyalty points by simply asking them to give a missed call to your number.</li> 
                </ul>
                 </p>
                <span>
                    <h4>Questions or concerns?</h4>
                    <p>This privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto: info@beazy.in">info@beazy.in</a></p>
                </span>
                <span><h4>Personal information you disclose to us</h4><p>To avail certain services on the Beazy application, we collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p></span>
                <span>
                    <h4>Personal Information Provided by You.</h4>
                    <p> Personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
                    <ul className='personal_info'>
                        <li>Phone numbers</li>
                        <li>Business entity information</li>
                        <li>Contact or authentication data names</li>    
                        <li>Contact preferences</li>
                        <li>SMS and call logs
                        </li>
                    </ul>
                    <p>In case you choose to decline to submit Information on the Beazy app we may not be able to provide certain services on the  to you. We will make reasonable efforts to notify you of the same at the appropriate time. In any case, we will not be liable and or responsible for the denial of certain services to you for due to not receiving necessary Information from you.</p>
                </span>
                <span>
                    <h4>Application Data</h4>
                    <p>If you use our application(s), we also may collect the following
                        information if you choose to provide us with access or permission:</p>
                        <ol>
                            <li>
                                <h4>Mobile Device Access</h4>
                                <p>We may request access or permission to certain features from your mobile device, including your mobile device's camera, contacts, sms messages, call logs, storage, and other features. If you wish to change our access or permissions, you may do so in your device's settings.</p>
                            </li>
                            <li>
                                <h4>Mobile Device Data.</h4>
                                <p>Automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.</p>
                            </li>
                            <li>
                                <h4>Push Notifications.</h4>
                                <p> 
                                    <span>We may request to send you push notifications regarding your account or certain features of the application(s). </span>
                                    <span>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes. </span>
                                    <span>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span>
                                </p>   
                            </li>
                        </ol>
                </span>
                <span>
                    <h4>Information automatically collected</h4>
                    <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
                    <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                    <p>The information we collect includes:</p>
                    <ul className='Information_automatically_collected'>
                        <li>
                            <h4>Log and Usage Data.</h4>
                            <p>Log and usage data is service-related, diagnostic,
                            usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</p>
                        </li>
                        <li>
                            <h4>Device Data.</h4>
                            <p>We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</p>
                        </li>
                    </ul>
                </span>
                <span>
                    <h2>HOW DO WE PROCESS YOUR INFORMATION?</h2>
                    <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
                    <ul>
                        <li>
                            <h4 className='item_head'>To facilitate account creation and authentication and otherwise manage user accounts: </h4>
                            <p className='item_content'>We  may process your information so you can create and log in to your account, as well as keep your account in working order.</p>
                        </li>
                        <li>
                            <h4>To deliver and facilitate delivery of services to the user:</h4>
                            <p>We may process your information to provide you with the requested service.</p>
                        </li>
                        <li>
                            <h4>To respond to user inquiries/offer support to users: </h4>
                            <p>We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</p>
                        </li>
                        <li>
                            <h4>To send administrative information to you:</h4>
                            <p>We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</p>
                        </li>
                        <li>
                            <h4>To fulfill and manage your orders:</h4>
                            <p>We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</p>
                        </li>
                        <li>
                            <h4>To request feedback:</h4>
                            <p>We may process your information when necessary to request feedback and to contact you about your use of our Services.</p>
                        </li>
                        <li>
                            <h4>To identify usage trends:</h4>
                            <p>We may process information about how you use our Services to better understand how they are being used so we can improve them.</p>
                        </li>
                        <li>
                            <h4>To comply with our legal obligations:</h4>
                            <p>We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.</p>
                        </li>
                    </ul>
                    <span>
                        <h3>DO WE MAKE UPDATES TO THIS NOTICE?</h3>
                        <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
                    </span>
                </span>
                <span>
                    <h3>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
                    <p>If you have questions or comments about this notice, you may email us at <a href="mailto: info@beazy.in">info@beazy.in</a> or by post to:</p>
                    <span>
                       <p>Beazy tech private limited</p>
                       <p>Flat 1003, Tower 7</p>
                       <p>Gurgaon, Haryana, 122002, India</p>
                    </span>
                </span>
            </div>  
        </>
    );
}

export default PrivacyPolicy;
