import React from "react";
import "../css/Aboutus.css"
// import img from '../utils/2.webp'
function About(props) {
return (
    <div id='about'>
        <h1 className="about_heading">
            About Us
        </h1>
        {/* <div className="about_img">
            <img src={img} alt="none" />
        </div> */}
        <div className="about_body">
            <div className="about_body_img">
                <img src={props.image} alt='none'/>
                <h2>{props.title}</h2>
            </div>
            <div className='about_desc'>
                <h2>{props.title}</h2>
                <p>
                Blending the convenience of Kiranas with the experience of modern stores to create Kiranas of the future.
                {/* We at Beazy are on a mission to organize small format retailers data and make it accessible and useful. 
                Building India’s first technology platform to cater to the needs of small format retailers.
                Transforming the way business is done, one transaction at a time by equipping small format kirana ecosystem 
                with the right tools- access to working capital, recommendations for the right product mix, online ordering, 
                and increased customer base.</p> */}
                </p>
                <a href='https://play.google.com/store/apps/details?id=com.beazy' className='cv-btn'>Download</a>
            </div>
        </div>
    </div>
)

}
export default About;